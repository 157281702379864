import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import CookieConsent from "react-cookie-consent"
import { Helmet } from "react-helmet"
import "./Layout.module.scss"

import { Header } from "../header/Header"
import { AgeGate } from "../age-gate/AgeGate"
import OGImage from "../../../images/ogimage.png"

const Layout = ({ children, showHeaderCta, dash, hide }) => {
  const [showAgeGate, setShowAgeGate] = useState(false)
  useEffect(() => {
    if (typeof window === "object") {
      setShowAgeGate(window.localStorage.getItem("18") ? false : true)
    }
  }, [])

  return (
    <>
      <Helmet>
        <meta name="og:image" content={`${OGImage}`} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content={"@TennentsLager"} />
        <meta name="twitter:title" content="Pintless | Tennent's" />
        <meta
          name="twitter:description"
          content="We get it, even the most creative of minds are beginning to flounder at the thought of coming up with another quarantine quiz... so let us help. Simply pick your questions from our many packs, share with pals and let the fun begin from the comfort of your home."
        />
        {`
        <!-- Made by https://neu.studio -->

        `}
        <script>
          {`

var $buoop = {required:{e:-4,f:-3,o:-3,s:-3,c:-3},insecure:true,api:2020.04 }; 
function $buo_f(){ 
 var e = document.createElement("script"); 
 e.src = "//browser-update.org/update.min.js"; 
 document.body.appendChild(e);
};
try {document.addEventListener("DOMContentLoaded", $buo_f,false)}
catch(e){window.attachEvent("onload", $buo_f)}
`}
        </script>

        <script>
          {`<!-- Facebook Pixel Code -->

<script>


!function(f,b,e,v,n,t,s)



{if(f.fbq)return;n=f.fbq=function(){n.callMethod?



n.callMethod.apply(n,arguments):n.queue.push(arguments)};



if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';



n.queue=[];t=b.createElement(e);t.async=!0;



t.src=v;s=b.getElementsByTagName(e)[0];



s.parentNode.insertBefore(t,s)}(window,document,'script',



'https://connect.facebook.net/en_US/fbevents.js');



fbq('init', '539605943415450');



fbq('track', 'PageView');


</script>

<noscript>

<img height="1" width="1"

src="https://www.facebook.com/tr?id=539605943415450&ev=PageView

&noscript=1"/>

</noscript>

<!-- End Facebook Pixel Code -->`}
        </script>
        <meta charSet="utf-8" />
        <title>Tennent's | Pintless</title>
      </Helmet>
      <div
        style={
          hide
            ? {
                opacity: 0,
              }
            : {
                opacity: 1,
              }
        }
      >
        <Header showCta={showHeaderCta || false} dash={dash} />
      </div>
      <main>{children}</main>
      <CookieConsent
        buttonText="I Accept"
        buttonStyle={{ backround: "#ffc72c", fontSize: "16px" }}
      >
        This website uses cookies to enhance the user experience.
        <a
          style={{ color: "#eaeaea", marginLeft: 10, fontSize: 16 }}
          href="https://tennents.co.uk/privacy-policy/"
          target="_blank"
        >
          Privacy Policy
        </a>
        <a
          style={{ color: "#eaeaea", marginLeft: 10, fontSize: 16 }}
          href="https://tennents.co.uk/terms-and-conditions/"
          target="_blank"
        >
          Terms and Conditions
        </a>
      </CookieConsent>
      {showAgeGate && <AgeGate close={() => setShowAgeGate(false)} />}
    </>
  )
}

export default Layout
